import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Menu, MenuItem, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { GridValueGetterParams } from '@mui/x-data-grid';
import { getStatusColor, getStatusFontColor } from 'app/shared/util/StatusColor';

import MainContainer from '../../shared/components/MainContainer';
import { urlQueryToObj } from 'app/shared/util/buildQuery';
import { useLocationQuery, useLocationQuerySet } from 'app/shared/util/useLocationQuery';

import { fetchSessionPackagesList, resetData } from './sessionPackagesApiService';
import { RootState, AppDispatch } from '../../redux/store';

import AddSessionPackageModalForm from './modals/AddSessionPackageModalForm';
import { DEFAULT_PAGESIZE, AUTHORITIES } from 'app/config/constants';
import DeactivateSessionPackageModal from './modals/DeactivateSessionPackageModal';
import { useAuth } from '../../../AuthContext';
import { getStatusLabel } from 'app/shared/util/ToCamelCase';

const SessionPackages: React.FC = () => {
	const dispatch = useDispatch<AppDispatch>();
	const searchParams = useLocationQuery();
	const setQuery = useLocationQuerySet();
	const loading = useSelector((state: RootState) => state.sessionPackages.loading);
	const error = useSelector((state: RootState) => state.sessionPackages.error);

	const sessionPackageData: any = useSelector((state: RootState) => state?.sessionPackages?.data);

	const { user } = useAuth();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
	const [newFormData, setNewFormData] = useState<any>();
	const [pageSize] = React.useState(1000);
	const { t } = useTranslation();

	const fetchSessionPackageListWithParams = () => {
		const query: any = urlQueryToObj(window.location.search);

		dispatch(
			fetchSessionPackagesList({
				...query,
				pageSize: query.pageSize || DEFAULT_PAGESIZE,
				page: query.page || 1,
			})
		);
	};

	useEffect(() => {
		fetchSessionPackageListWithParams();
		return () => {
			dispatch(resetData());
		};
	}, []);

	if (error) {
		return <div>Error: {error}</div>;
	}

	const handleEditClick = async (row: any) => {
		// Perform edit action
		handleOpenModal();
		setNewFormData(row);
	};

	const handleDeactivateClick = async (row: any) => {
		// Perform deactivate action
		handleMenuClose();
		setIsChangeStatusModalOpen(true);
		setNewFormData(row);
	};

	/**
	 * Handle opening the modal window
	 * @function
	 * @returns modal open status state with the updated values
	 */
	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	/**
	 * Handle closing the modal window
	 * @function
	 * @returns modal oepn status state with the updated values
	 */
	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	/**
	 * Handle closing the modal window
	 * @function
	 * @returns change status state with the updated values
	 */
	const handleDeactivateCoachCloseModal = () => {
		setIsChangeStatusModalOpen(false);
	};

	/**
	 * Handle closing the menu close
	 * @function
	 * @returns change status state with the updated values
	 */
	const handleMenuClose = () => {
		setIsChangeStatusModalOpen(false);
	};

	const handlePageChange = (paginationModel: any) => {
		setQuery({ ...searchParams, ...paginationModel });
		fetchSessionPackageListWithParams();
	};

	const ActionsCell = ({ row, onEditClick, onStatusChangeClick }: any) => {
		const [anchorEl, setAnchorEl] = React.useState(null);

		const handleClick = (event: any) => {
			setAnchorEl(event.currentTarget);
		};

		const handleClose = () => {
			setAnchorEl(null);
		};

		return (
			<div>
				<IconButton onClick={handleClick}>
					<MoreHorizIcon />
				</IconButton>
				<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
					<MenuItem
						onClick={() => {
							onEditClick();
							handleClose();
						}}
					>
						{user?.userType === AUTHORITIES.club_admin ? t('view') : t('edit')}
					</MenuItem>
					{user?.userType !== AUTHORITIES.club_admin && (
						<MenuItem
							onClick={() => {
								onStatusChangeClick();
								handleClose();
							}}
							sx={{
								...(row?.status === 'active' && {
									fontWeight: 600,
									color: '#EB5757',
								}),
							}}
						>
							{row?.status === 'active' ? t('deactivate') : t('reactivate')}
						</MenuItem>
					)}
				</Menu>
			</div>
		);
	};

	const columns = [
		{
			field: 'name',
			headerName: t('packageName'),
			flex: 1,
		},
		{
			field: 'price',
			headerName: t('price'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => `€${params?.row?.price || ''}`,
		},
		{
			field: 'noOfCredits',
			headerName: t('sessionCredits'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => `${params?.row?.noOfCredits || 'NA'}`,
		},
		{
			field: 'noOfFreeCredits',
			headerName: t('freeCredits'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) =>
				`${params?.row?.noOfFreeCredits || '0'}`,
		},
		{
			field: 'applicableTo',
			headerName: t('applicableTo'),
			flex: 1,
			sortable: false,
			renderCell: (params: any) => (
				<>
					{params?.row?.isUserApplicable && (
						<Chip
							label={t('user')}
							style={{
								backgroundColor: '#826AF929',
								color: '#826AF9',
                borderRadius: '8px',
                marginRight: '5px'
							}}
						/>
					)}
					{params?.row?.isCorporateApplicable && (
						<Chip
							label={t('corporate')}
							style={{
								backgroundColor: '#826AF929',
								color: '#826AF9',
                borderRadius: '8px'
							}}
						/>
					)}
				</>
			),
		},
		{
			field: 'productStatus',
			headerName: t('productStatus'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => `${params.row.status || 0} `,
			renderCell: (params: any) => (
				<Chip
					label={getStatusLabel(params?.row?.status, t)}
					style={{
						backgroundColor: getStatusColor(params?.row?.status),
						color: getStatusFontColor(params?.row?.status),
					}}
				/>
			),
		},
		{
			field: 'action',
			headerName: t('actions'),
			width: 120,
			renderCell: (params: GridValueGetterParams) => (
				<ActionsCell
					row={params?.row}
					onEditClick={() => handleEditClick(params.row)}
					onStatusChangeClick={() => handleDeactivateClick(params.row)}
				/>
			),
		},
	];

	const pageName = t('bookingSessionPackage');
	const btnText = t('addNewSessionPackage');

	return (
		<>
			<MainContainer
				columns={columns}
				rows={sessionPackageData?.data || []}
				features={[]}
				pageName={pageName}
				btnText={user?.userType !== AUTHORITIES.club_admin ? btnText : ''}
				menuName="Session Packages"
				onCurrentPageChange={handlePageChange}
				pageSize={pageSize}
				loading={loading}
				totalRecords={sessionPackageData?.totalCount}
				meta={{
					page: parseInt(searchParams?.page) || 1,
					pageSize: parseInt(searchParams?.pageSize),
					totalCount: sessionPackageData?.totalCount,
				}}
				callback={() => {
					fetchSessionPackageListWithParams();
				}}
				checkboxSelection={false}
			/>

			{isChangeStatusModalOpen && (
				<DeactivateSessionPackageModal
					openModal={isChangeStatusModalOpen}
					onClose={handleDeactivateCoachCloseModal}
					id={''}
					formData={newFormData}
					entityName="coachDetail"
					modalTxt="club"
				/>
			)}
			{isModalOpen && (
				<AddSessionPackageModalForm
					open={isModalOpen}
					onClose={handleCloseModal}
					action="edit"
					formData={newFormData}
					page="coachListing"
				/>
			)}
		</>
	);
};

export default SessionPackages;
